import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted, {
    iconPack: 'fontawesome',
    duration: 3000,
    closeOnSwipe: true
})

Vue.toasted.register(
    'defaultSuccess',
    payload => !payload.msg ? 'Operação realizada com sucesso!' : payload.msg,
    {
        type: 'success', 
        icon: 'check', 
        theme: "bubble", 
        position: "top-center", 
        // action : {
        //     text : 'OK',
        //     onClick : (e, toastObject) => {
        //         toastObject.goAway(0);
        //     }
        // },  
    })

Vue.toasted.register(
    'defaultError',
    payload => !payload.msg ? 'Ocorreu um erro inesperado.' : payload.msg,
    {
        type : 'error', 
        icon : 'times', 
        theme: "bubble", 
        position: "top-center",
        action : {
            text : 'OK',
            onClick : (e, toastObject) => {
                toastObject.goAway(0);
            }
        },  
    }
)

Vue.toasted.register(
    'defaultInfo',
    payload => !payload.msg ? 'Há requisitos ausentes!' : payload.msg,
    { type : 'info', icon : 'info', theme: "bubble", position: "top-center" }
)