import Vue from 'vue'
import VueRouter from 'vue-router'
import { userKey } from '../global'
import store from './store'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    component: () => import(/* webpackChunkName: "SiteEstatico" */ "../components/template/SiteEstatico"),
    // component: () => import(/* webpackChunkName: "vagasHome" */ "../components/paginas/vagasMain"),
    children: [
    {
        name: 'Home',
        path: '/',
        component: () => import(/* webpackChunkName: "home" */ "../components/paginas/Home"),
        // component: () => import(/* webpackChunkName: "vagasHome" */ "../components/paginas/vagasMain"),
    }, {
        name: 'Sobre',
        path: '/Sobre',
        component: () => import(/* webpackChunkName: "sobre" */ "../components/paginas/sobre"),
    }, {
        name: 'Auth',
        path: '/Login',
        component: () => import(/* webpackChunkName: "auth" */ "../views/site/Auth2.vue"),
    }, {
        name: 'CadastroUsuario',  // rota de cadastro de cliente
        path: '/cadastro',
        component: () => import(/* webpackChunkName: "CadastroUsuario" */ "../views/site/CadastroUsuario.vue"),
    }, {
        name: 'batinteligente',
        path: '/batinteligente',
        component: () => import(/* webpackChunkName: "batinteligente" */ "../components/paginas/baterias"),
    },{
        name: 'BateriasInteligentes',
        path: '/bateriasinteligentes',
        component: () => import(/* webpackChunkName: "bateriasinteligentes" */ "../views/site/BateriasInteligentes.vue"),
    },{
        name: 'Bms',
        path: '/KinderBMS',
        component: () => import(/* webpackChunkName: "bms" */ "../components/paginas/Bms"),
    }, {
        name: 'NovaIdentidade',
        path: '/identidadeHion',
        component: () => import(/* webpackChunkName: "identidadeHion" */ "../components/paginas/novaidentidade"),
    }, {
        name: 'VagasMain',
        path: '/temosVagas',
        component: () => import(/* webpackChunkName: "vagasMain" */ "../components/paginas/vagasMain"),
    }, {
        name: 'VagasFront',
        path: '/temosVagas/frontend',
        component: () => import(/* webpackChunkName: "vagasFront" */ "../components/paginas/vagasFront"),
    }, {
        name: 'VagasBack',
        path: '/temosVagas/backend',
        component: () => import(/* webpackChunkName: "vagasBack" */ "../components/paginas/vagasBack"),
    }, {
        name: 'VagasElet',
        path: '/temosVagas/eletronica',
        component: () => import(/* webpackChunkName: "vagasElet" */ "../components/paginas/vagasElet"),
    }, {
        name: 'VagasDiag',
        path: '/temosVagas/diagnostico',
        component: () => import(/* webpackChunkName: "vagasDiag" */ "../components/paginas/vagasDiag"),
    }, {
        name: 'VagasMeca',
        path: '/temosVagas/mecanico',
        component: () => import(/* webpackChunkName: "vagasMeca" */ "../components/paginas/vagasMeca"),
    }, {
        name: 'VagasEsta',
        path: '/temosVagas/estagio',
        component: () => import(/* webpackChunkName: "vagasFront" */ "../components/paginas/vagasEsta"),
    }, {
        name: 'NossaEquipe',
        path: '/NossaEquipe',
        component: () => import(/* webpackChunkName: "NossaEquipe" */ "../components/paginas/NossaEquipe"),
    }
]
}, 
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
{
    name: 'Interativa',
    path: '/PlataformaHION',
    meta: { autenticacao: true },  // PARA ENTRAR AQUI, PRECISA ESTAR LOGADO!!!!!!!!
    beforeEnter: (to, from, next) => {

        // se está acessando uma rota da plataforma sem estar logado...
        // ACESSANDO METACAMPO DO PARENT -> https://github.com/vuejs/vue-router/issues/704
        // -> https://stackoverflow.com/questions/40734378/vue-router-inherit-parent-properties
        if(to.matched.some(parent => parent.meta.autenticacao) && !store.state.usuario) {
            // console.log('Sai daí doidão')
            Vue.toasted.global.defaultError({ msg : 'Acesso não autorizado. Por favor, faça seu login' });
            next({ path: '/Login' })
        }
        else {
            // console.log(store.state.usuario)
            next()  // usuário está logado
        }
    },

    // POR ENQUANTO, QUALQUER PERFIL HION TEM ACESSO A QUALQUER ROTA. POR ENQUANTO!!!!

    component: () => import(/* webpackChunkName: "interativa" */ "../components/plataforma/Interativa"),
    children: [{
        name: 'HomeHionSocio',
        path: '/PlataformaHION/Socio',
        component: () => import(/* webpackChunkName: "homeHionSocio" */ "../components/plataforma/Home/HomeHionSocio"),
        meta: { requiresHion: true, requiresPrt: true }
    }, {
        name: 'HomeHionDev',
        path: '/PlataformaHION/Dev',
        component: () => import(/* webpackChunkName: "homeHionDev" */ "../components/plataforma/Home/HomeHionDev"),
        meta: { requiresHion: true }
    }, {
        name: 'HomeHionAdm',
        path: '/PlataformaHION/Adm',
        component: () => import(/* webpackChunkName: "homeHionAdm" */ "../components/plataforma/Home/HomeHionAdm"),
        meta: { requiresHion: true }
    },
    {
        name: 'HomeHionCampo',
        path: '/PlataformaHION/Campo',
        component: () => import(/* webpackChunkName: "homeHionCampo" */ "../components/plataforma/Home/HomeHionCampo"),
        meta: { requiresHion: true }
    }, {
        name: 'HomeHionProducao',
        path: '/PlataformaHION/producao',
        component: () => import(/* webpackChunkName: "homeHionDev" */ "../components/plataforma/Home/HomeHionProducao.vue"),
        meta: { requiresHion: true }
    }, {
        name: 'HomeInterativaN1',
        path: '/PlataformaHION/painelprincipal',
        component: () => import(/* webpackChunkName: "homeInterativaN1" */ "../components/plataforma/Home/HomeInterativaN1.vue"),
    }, {
        name: 'HomeSelecionarPerfil',
        path: '/PlataformaHION/selecionar-perfil',
        component: () => import(/* webpackChunkName: "homeInterativaN2" */ "../views/plataforma/Home/HomeSelecionarPerfil.vue"),
    }, {
        name: 'HomeInterativaN3',
        path: '/PlataformaHION/painelprincipalfunc',
        component: () => import(/* webpackChunkName: "homeInterativaN3" */ "../components/plataforma/Home/HomeInterativaN3"),
    }, {
        name: 'HomeInterativaN4',
        path: '/PlataformaHION/bemvindo',
        component: () => import(/* webpackChunkName: "homeInterativaN4" */ "../views/plataforma/Home/HomeInterativaN4.vue"),
    },
    
    /* SOCIO **************************************************************/
    {
        name: 'HionBMSsocio',
        path: '/PlataformaHION/Socio/BmsHionSocio',
        component: () => import(/* webpackChunkName: "HionBMSsocio" */ "../components/plataforma/Hion/BmsHion"),
        meta: { requiresHion: true, requiresPrt: true }
    },
    {
        name: 'BMSsocioCadastro',
        path: '/PlataformaHION/Socio/CadastroBMS',
        component: () => import(/* webpackChunkName: "BMSsocioCadastro" */ "../components/plataforma/Hion/CadastroBMS"),
        meta: { requiresHion: true, requiresPrt: true }
    },
    {
        name: 'dbBMS',
        path: '/PlataformaHION/Hion/dbBMS',
        component: () => import(/* webpackChunkName: "dbBMS" */ "../components/plataforma/Hion/dbBMS"),
        meta: { requiresHion: true }
    },
    {
        name: 'HionBATsocio',
        path: '/PlataformaHION/Socio/BateriasHionSocio',
        component: () => import(/* webpackChunkName: "HionBATsocio" */ "../components/plataforma/Hion/BateriasHionSocio"),
        meta: { requiresHion: true, requiresPrt: true }
    },
    {
        name: 'HionFUNCsocio',
        path: '/PlataformaHION/Socio/FuncHionSocio',
        component: () => import(/* webpackChunkName: "HionFUNCsocio" */ "../components/plataforma/Hion/FuncHionSocio"),
        meta: { requiresHion: true, requiresPrt: true }
    },
    {
        name: 'HionCLIsocio',
        path: '/PlataformaHION/Socio/ClienteHionSocio',
        component: () => import(/* webpackChunkName: "HionCLIsocio" */ "../components/plataforma/Hion/ClienteHionSocio"),
        meta: { requiresHion: true, requiresPrt: true }
    },
    {
        name: 'HionFINsocio',
        path: '/PlataformaHION/Socio/FinancaHionSocio',
        component: () => import(/* webpackChunkName: "HionFINsocio" */ "../components/plataforma/Hion/FinancaHionSocio"),
        meta: { requiresHion: true, requiresPrt: true }
    },
    {
        name: 'HionDEVsocio',
        path: '/PlataformaHION/Socio/DevHionSocio',
        component: () => import(/* webpackChunkName: "HionDEVsocio" */ "../components/plataforma/Hion/DevHionSocio"),
        meta: { requiresHion: true, requiresPrt: true }
    },
    {
        name: 'HionSUPsocio',
        path: '/PlataformaHION/Socio/SupHionSocio',
        component: () => import(/* webpackChunkName: "HionSUPsocio" */ "../components/plataforma/Hion/SupHionSocio"),
        meta: { requiresHion: true, requiresPrt: true }
    },
    {
        name: 'HionADMsocio',
        path: '/PlataformaHION/Socio/AdmHionSocio',
        component: () => import(/* webpackChunkName: "HionADMsocio" */ "../components/plataforma/Hion/AdmHionSocio"),
        meta: { requiresHion: true, requiresPrt: true }
    },
    {
        name: 'NovosPedidos',
        path: '/PlataformaHION/Socio/AdmHion/novosPedidos',
        component: () => import(/* webpackChunkName: "novosPedidos" */ "../components/plataforma/Hion/novosPedidos"),
        meta: { requiresHion: true, requiresPrt: true }
    },
    {
        name: 'CadastroHion',
        path: '/PlataformaHION/Hion/CadastroHion',
        component: () => import(/* webpackChunkName: "CadastroHion" */ "../components/plataforma/Hion/CadastroHion"),
        meta: { requiresHion: true, requiresPrt: true }
    },  
    {
        name: 'dbCadastroHion',
        path: '/PlataformaHION/Hion/dbCadastroHion',
        component: () => import(/* webpackChunkName: "dbCadastroHion" */ "../components/plataforma/Hion/dbCadastroHion"),
        meta: { requiresHion: true }
    }, 
    // {
    //     name: 'CadastroCliente',
    //     path: '/PlataformaHION/Hion/CadastroCliente',
    //     component: () => import(/* webpackChunkName: "CadastroCliente" */ "../components/plataforma/Hion/CadastroCliente"),
    //     meta: { requiresHion: true, requiresPrt: true }
    // },
    {
        name: 'dbCadastroCliente',
        path: '/PlataformaHION/Hion/dbCadastroCliente',
        component: () => import(/* webpackChunkName: "dbCadastroCliente" */ "../components/plataforma/Hion/dbCadastroCliente"),
        meta: { requiresHion: true }
    },
    {
        name: 'dbUsers',
        path: '/PlataformaHION/Hion/dbUsers',
        component: () => import(/* webpackChunkName: "dbUsers" */ "../components/plataforma/Hion/dbUsers"),
        meta: { requiresHion: true }
    },
    {
        name: 'AlterarSms',
        path: '/PlataformaHION/Hion/alterarsms',
        component: () => import(/* webpackChunkName: "dbUsers" */ "../components/plataforma/Hion/AlterarSms.vue"),
        meta: { requiresHion: true }
    },

    /* DEV ****************************************************************/
    {
        name: 'HionBMSdev',
        path: '/PlataformaHION/HionDEV/BmsHion',
        component: () => import(/* webpackChunkName: "HionBMSdev" */ "../components/plataforma/HionDEV/BmsHion"),
        meta: { requiresHion: true }
    },
    {
        name: 'HionBATdev',
        path: '/PlataformaHION/HionDEV/BateriasHion',
        component: () => import(/* webpackChunkName: "HionBATdev" */ "../components/plataforma/HionDEV/BateriasHion"),
        meta: { requiresHion: true }
    },
    {
        name: 'HionDEVdev',
        path: '/PlataformaHION/HionDEV/DevHion',
        component: () => import(/* webpackChunkName: "HionDEVdev" */ "../components/plataforma/HionDEV/DevHion"),
        meta: { requiresHion: true }
    },
    {
        name: 'HionSUPdev',
        path: '/PlataformaHION/HionDEV/SupHion',
        component: () => import(/* webpackChunkName: "HionSUPdev" */ "../components/plataforma/HionDEV/SupHion"),
        meta: { requiresHion: true }
    },

    /* ADM **************************************************************/
    {
        name: 'HionFUNCadm',
        path: '/PlataformaHION/HionADM/FuncHionadm',
        component: () => import(/* webpackChunkName: "HionFUNCadm" */ "../components/plataforma/HionADM/FuncHionadm"),
        meta: { requiresHion: true }
    },
    {
        name: 'HionCLIadm',
        path: '/PlataformaHION/HionADM/ClienteHionadm',
        component: () => import(/* webpackChunkName: "HionCLIadm" */ "../components/plataforma/HionADM/ClienteHionadm"),
        meta: { requiresHion: true }
    },
    {
        name: 'HionFINadm',
        path: '/PlataformaHION/HionADM/FinancaHionadm',
        component: () => import(/* webpackChunkName: "HionFINadm" */ "../components/plataforma/HionADM/FinancaHionadm"),
        meta: { requiresHion: true }
    },
    {
        name: 'HionSUPadm',
        path: '/PlataformaHION/HionADM/SupHionadm',
        component: () => import(/* webpackChunkName: "HionSUPadm" */ "../components/plataforma/HionADM/SupHionadm"),
        meta: { requiresHion: true }
    },
    {
        name: 'HionADMadm',
        path: '/PlataformaHION/HionADM/AdmHionadm',
        component: () => import(/* webpackChunkName: "HionADMadm" */ "../components/plataforma/HionADM/AdmHionadm"),
        meta: { requiresHion: true }
    },

    /* TiME DE CAMPO **************************************************************/
    {
        name: 'HionTimeCampo',
        path: '/PlataformaHION/HionCAMPO/TimeCampo',
        component: () => import(/* webpackChunkName: "HionTimeCampo" */ "../components/plataforma/HionCampo/TimeCampo"),
        meta: { requiresHion: true }
    },
    {
        name: 'HionSUPcampo',
        path: '/PlataformaHION/HionCAMPO/Suporte',
        component: () => import(/* webpackChunkName: "HionCLIadm" */ "../components/plataforma/HionCampo/SupHionCampo"),
        meta: { requiresHion: true }
    },

    /*************************************************************************** */
    /* N1 */
    {
        name: 'BateriaInterativa',
        path: '/PlataformaHION/Baterias',
        component: () => import(/* webpackChunkName: "bateriaInterativa" */ "../components/plataforma/Home/BateriaInterativa"),
    },
    {
        name: 'ColaboradoresN1',
        path: '/PlataformaHION/administrador/colaboradores',
        component: () => import(/* webpackChunkName: "ColaboradoresN1" */ "../components/plataforma/ClienteN1/Colaboradores"),
    },
    {
        name: 'CadastrarColaborador',
        path: '/PlataformaHION/administrador/colaboradores/cadastrar',
        component: () => import(/* webpackChunkName: "ColaboradoresN1" */ "../components/plataforma/ClienteN1/CadastrarColaborador"),
    },
    {
        name: 'EnergiaN1',
        path: '/PlataformaHION/administrador/Energia',
        component: () => import(/* webpackChunkName: "EnergiaN1" */ "../components/plataforma/ClienteN1/Energia"),
    },
    {
        name: 'EquipamentosN1',
        path: '/PlataformaHION/administrador/Equipamentos',
        component: () => import(/* webpackChunkName: "EquipamentosN1" */ "../components/plataforma/ClienteN1/Equipamentos"),
    },
    {
        name: 'MetasN1',
        path: '/PlataformaHION/administrador/Metas',
        component: () => import(/* webpackChunkName: "MetasN1" */ "../components/plataforma/ClienteN1/Metas"),
    },
    {
        name: 'RelatoriosN1',
        path: '/PlataformaHION/administrador/Relatorios',
        component: () => import(/* webpackChunkName: "RelatoriosN1" */ "../components/plataforma/ClienteN1/Relatorios"),
    },
    {
        name: 'PedidosN1',
        path: '/PlataformaHION/administrador/Pedidos',
        component: () => import(/* webpackChunkName: "PedidosN1" */ "../components/plataforma/ClienteN1/Pedidos"),
    },
    {
        name: 'SuporteN1',
        path: '/PlataformaHION/administrador/Suporte',
        component: () => import(/* webpackChunkName: "SuporteN1" */ "../components/plataforma/ClienteN1/Suporte"),
    },
    {
        name: 'AdministracaoN1',
        path: '/PlataformaHION/administrador/Administracao',
        component: () => import(/* webpackChunkName: "AdministracaoN1" */ "../components/plataforma/ClienteN1/Administracao"),
    },

    /*************************************************************************** */
    /* Conteudo Interativo MULTI-NIVEL */
    {
        name: 'Telemetria',
        path: '/PlataformaHION/Baterias/TempoReal',
        component: () => import(/* webpackChunkName: "TelemetriaTempoReal" */ "../components/plataforma/pih/Telemetria"),
        props: true
    },
    {
        name: 'ConfigBMSprf',
        path: '/PlataformaHION/Baterias/Preferencias',
        component: () => import(/* webpackChunkName: "ConfigBMSprf" */ "../components/plataforma/pih/configBMSprf"),
        props: true
    },
    {
        name: 'EditarPerfil',
        path: '/PlataformaHION/meuperfil',
        component: () => import(/* webpackChunkName: "editarperfil" */ "../views/plataforma/EditarPerfil.vue"),
    },
    {
        name: 'EditarConfiguracoes',
        path: '/PlataformaHION/minhasconfiguracoes',
        component: () => import(/* webpackChunkName: "editarperfil" */ "../views/plataforma/EditarConfiguracoes.vue"),
    },
    

    /*************************************************************************** */
    {
        name: 'BateriaInterativaDemo',
        path: '/PlataformaHION/demonstracao',
        component: () => import(/* webpackChunkName: "bateriaInterativademo" */ "../views/plataforma/BateriaInterativaDemo.vue"),
    },
    /****************************************************************************/
    ]
}]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition
        } else {
          return { x: 0, y: 0 }
        }
      }
})


// TESTAR ISSO PARA CONTINUAR O NAVIGATION GUARDS
// function hionAuth(to, from, next) {
//     console.log('entrei aqui corno');
//     // Essa função subentende que o usuário já está logado (pq verificamos isso no parent)
//     // Se a meta requiresHion é true E a chave 'hion' do usuário NÃO É, ou seja, ele não é um colaborador HION
//     if(to.meta.requiresHion && !store.state.usuario.hion) {
//         // eu poderia usar o from aqui
//         store.commit('setUser', null);
//         window.localStorage.removeItem(userKey);
//         Vue.toasted.global.defaultError({ msg : 'Seu usuário não é habilitado para obter este acesso a esta página!' });
//         next({ path: '/Login' })
//     } else if (to.meta.requiresHion && store.state.usuario.hion) {
//         next()
//     }
// }

// Guardião das rotas para colaboradores HION. Por enquanto está assim!!!
// router.beforeEach((to, from, next) => {

//     // Se a rota acessada tem autenticação
//     if(to.matched.some(record => record.meta.autenticacao)) {

//         // Se, além de estar logado, o usuário quer acessar uma rota para COLABORADORES HION
//         if(to.matched.some(record => record.meta.requiresHion)) {
//             if(!store.state.usuario.hion) {  // HION FALSE É PRA TODOS OS USUÁRIOS QUE NÃO SÃO COLABORADORES HION
//                  next({ path: '/Login' })  // 
//             } else {
//                 next()  // O USUÁRIO É HION!!!
//                 // Por enquanto, não tem verificação de prt, então qualquer colaborador hion acessa rotas internas, por enquanto...
//             }
//         }
//     }
//     // Se não tem, o usuário não está acessando rotas internas da paltaforma
//     else {
//         next()
//     }
// })

// router.beforeEach((to, from, next) => {
//     ////////////////////////////////////////////////////////////////////////
//     const json = localStorage.getItem(userKey)
//     const user = JSON.parse(json)
//     let requer = 0;
//     ////////////////////////////////////////////////////////////////////////
//     if(to.matched.some(record => record.meta.requiresHion)) {
//         requer |= (1 << 0); //seta bit hion
//     }

//     if(to.matched.some(record => record.meta.requiresPrt)) {
//         requer |= (1 << 1); //seta bit prt
//     }
//     ////////////////////////////////////////////////////////////////////////
//     switch(requer) {
//         case 0x01:
//             if(user && user.hion) next();
//                 else requer = 0xff;
//             break;
//         case 0x02:
//             if(user && (user.prt & 0b10000000) && (user.prt & 0b00000001)) next();
//                 else requer = 0xff;
//             break;
//         case 0x03:
//             if(user && user.hion && (user.prt & 0b10000000) && (user.prt & 0b00000001)) next();
//                 else requer = 0xff;
//             break;
//         default:
//             requer = 0;
//             break;
//     }
//     ////////////////////////////////////////////////////////////////////////////////////////////
    
//     if(requer === 0) next()

//     if(requer === 0xff) {
//         store.commit('setUser', null);
//         window.localStorage.removeItem(userKey);
//         Vue.toasted.global.defaultError({ msg : 'Seu usuário não é habilitado para obter este acesso a esta página!' });
//         next({ path: '/Login' })
//     }
// })

export default router
