import Vue from 'vue'
import axios from 'axios'
import { userKey } from '../global'
import store from './store'
import router from './router'

axios.defaults.crossDomain = true;
///////////////////////////////////////////////////////////////////////////////////////////////////

const success = res => res
const error = err => {
    if (401 === err.response.status || 403 === err.response.status) {
        Vue.toasted.global.defaultError({ msg : `${err.response.data.msg}`});
        store.commit('setUser', null);
        window.localStorage.removeItem(userKey); 
        if (router.currentRoute.path !== '/Login') router.push({ name: 'Auth' });
    } else {
        return Promise.reject(err)
    }
}

// axios.interceptors.response.use(success, error)  // PAUSEI O KICK DA PLATAFORMA QUANDO ERROS OCORREM