import Vue from 'vue'

import App from './App'

import './global'
import './config/bootstrap'
import './config/msgs'
import './config/axios'
import './config/mq'
import './config/vuescrollto'
import './config/apex'
//import './assets/css/style.css'

import store from './config/store'
import router from './config/router'

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
