import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import { VBTooltip } from 'bootstrap-vue'
import { CollapsePlugin } from 'bootstrap-vue'
import { PaginationPlugin } from 'bootstrap-vue'
import { ImagePlugin } from 'bootstrap-vue'
import { FormPlugin } from 'bootstrap-vue'
import { TablePlugin } from 'bootstrap-vue'
import { ModalPlugin } from 'bootstrap-vue'
import { OverlayPlugin } from 'bootstrap-vue'
import { IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(CollapsePlugin)
Vue.directive('b-tooltip', VBTooltip)
Vue.use(OverlayPlugin)
Vue.use(PaginationPlugin)
Vue.use(ImagePlugin)
Vue.use(FormPlugin)
Vue.use(TablePlugin)
Vue.use(ModalPlugin)
Vue.use(IconsPlugin)
