import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

axios.defaults.crossDomain = true;

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        MenuVisivel: false,
        usuario: null,
        apelidoBMS: null,
        menuReduzido: false,
        menuViews: null,
        tema: 1  // 0 para claro, 1 para claro 2, 2 para escuro.
    },
    mutations: {
        alternaMenu(state, visibilidade) {
            if(!state.usuario) {
                state.MenuVisivel = false
                return
            }
            if(visibilidade === undefined) {
                state.MenuVisivel = !state.MenuVisivel    //Alterna visibilidade do menu interativo
            } else {
                state.MenuVisivel = visibilidade
            }
        },
        setUser(state, usuario) {
            // console.log(`Eu sou o token -> ${usuario.token}`)
            state.usuario = usuario
            // const teste = JSON.stringify(usuario)
            // console.log('OI EU SOU O TESTE', teste)
            // console.log(state.usuario.token)
            if(usuario) {
                // for (let [key, valor] of Object.entries(usuario)) {
                //     console.log('TOKEN ->')
                //     console.log(`${key}: ${valor}`)
                // }
                // console.log(`TOKEN -> ${}`)
                axios.defaults.headers.common['Authorization'] = `bearer ${state.usuario.token}`  // definindo usuário no header de qualquer requisição que enviar
                axios.defaults.baseURL = 'https://';
                state.MenuVisivel = true
            } else {
                delete axios.defaults.headers.common['Authorization']
                state.MenuVisivel = false
            }
        },
        BMSatual(state, entrada) {
            if (entrada == undefined || entrada == null ||
                entrada.apelido == undefined || entrada.apelido == null ||
                entrada.rota == undefined || entrada.rota == null) return
                
            state.apelidoBMS = {
                apelido: entrada.apelido,
                rota: entrada.rota
            }
        },
        
        reduzirMenu(state, payload) {
            if (payload == true) {
                state.menuReduzido = true
            }
            else {
                state.menuReduzido = false
            }
        },

        //recebe o estado e o payload que vamos usar para alteração
        alteraTema(state, payload) {
            if (state.tema != payload) {
                state.tema = payload
            }
        },

        setMenuViews(state, payload) {
            if (process.env.NODE_ENV === 'development') {
                console.log('Recebendo payload com os dados do menu')
                console.log(payload)
            }
            // if (state.menuViews === null) {  // problema aqui de não apagar a store quando muda de usuário.
                state.menuViews = payload
                // console.log(state.menuViews)
            // }
                // console.log(state.menuViews)
            // }
        }
    },
    
    //actions: {
        //fechaMenuAsync ({ commit }) {
            //setTimeout(function(){
                //commit('alternaMenu', false)
            //}, 1000);
        //}
    //}

})