<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: "App",
	components: { 
 
	}
}
</script>

<style>
	* {
		box-sizing: border-box;
	}
	html, body {
		margin: 0;
		width: 100%;
		height: 100vh;
		/* background: #2e2d2c; */
        /* problema do background cinza escuro dentro da plataforma */
	}

	#app {
		-webkit-font-smothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		min-height: 100%;
		min-width: 320px !important;
		max-width: 1920px !important;
		margin:0 auto;
		overflow-x: hidden;
		display: grid;
		grid-template-rows: auto 1fr auto;
		grid-template-columns: 100%;
		grid-template-areas:
			"header"
			"content"
			"footer"
	}
</style>